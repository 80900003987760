.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTab-root.filetab { 
  overflow: visible;
  background-color: #fff;
  position: relative;
  border-radius: 15px 15px 0px 0px;
  z-index: 2;
  margin-right: 12px;
  border-color: #d9d9d9;
  border-width: 2px;
  border-bottom-width: 0;
  border-style: solid;
  color: #b4b4b4;
  text-transform: none;
  font-size: 1.4rem;
  /* padding: 0 12px; */
  max-width: unset;
}

.MuiTab-root.filetab:before {
  height: 46px;
  width: 40px;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  content: "";
  position: absolute;
  right: -7px;
  top: 0px;
  -webkit-transform: skewX(20deg);
  transform: skewX(200deg);
  z-index: -99;
  border-color: #d9d9d9;
  border-width: 0px;
  border-right-width: 3px;
  border-style: solid;
}

.MuiTab-root.filetab.selected { 
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #262626;
  font-weight: bold;
}

.MuiTab-root.filetab.selected:before {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}


::-webkit-calendar-picker-indicator { margin-left:0 }

.search-input {
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 8px;
}