.react-calendar {
    border-radius: 4px;
    max-width: 100%;
    /* background: white; */
    font: inherit;
    line-height: 1.125em;
    border: 0;
    overflow: hidden;
  }

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font: inherit;
  font-size: 0.8em;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar button abbr{
  display: none;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  overflow: visible;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar__navigation {
  height: 36px;
  margin-bottom: 10px;
  justify-content: center;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button svg {
  display: inline-block;
  vertical-align: middle;
  color: #707070;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view {
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 2px 10px 0 #00000029;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 24px;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #838383;
}

.react-calendar__month-view__weekdays abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 0.5em;
  background: none;
  height: 50px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover .react-calendar__tile__day,
.react-calendar__tile:enabled:focus .cirlce{
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active .react-calendar__tile__day {
  background: #eb3524;
}
.react-calendar__tile--active:enabled:hover .react-calendar__tile__day,
.react-calendar__tile--active:enabled:focus .react-calendar__tile__day {
  background: #eb3524;
}
.react-calendar__tile--active .react-calendar__tile__day span,
.react-calendar__tile--active:enabled:hover .react-calendar__tile__day span,
.react-calendar__tile--active:enabled:focus .react-calendar__tile__day span{
  color: white !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.react-calendar__navigation__label {
  flex-grow: 0 !important;
  pointer-events: none;
  cursor: default;
  margin: 0 40px !important;
}
.react-calendar__navigation__label__labelText { 
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.25;
  color: #262626;
}

.react-calendar__navigation__arrow .MuiSvgIcon-root {
  height: 28px;
  width: 28px;
}

.react-calendar__tile__day_container {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.react-calendar__tile__day {
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.react-calendar__tile__day span {
  font-size: 1.2rem;
  line-height: 1.22;
  color: #262626;
}

.react-calendar__tile__indicator_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.react-calendar__tile__indicator {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #fd101b;
  align-self: center;
  margin: 0 2px
}

.react-calendar__tile__indicator_container div:nth-child(2){
  background-color: #1cbc5c;
}

.react-calendar__month-view__days__day--neighboringMonth span {
  color: #b4b4b4
}

.holiday span { 
  color: #fd101b;
}

.event-item {
  /* width: 472px; */
  max-height: 72px;
  box-shadow: 1px 3px 6px 0 #00000033;
  background-color: #ffffff;
  border-left: 4px solid #fd101b;
  padding: 8px 18px 16px 18px;
  margin-top: 8px;
  overflow: hidden;
}

.event-item-date {
  font-size: 1.2rem;
  line-height: 1.17;
  color: #b4b4b4;
  margin-bottom: 4px;
}

.event-item-title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  color: #000000;
}
