

.recent-documents-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4px);
    position: absolute;
    background: #fff;
    height: 350.5px;
    bottom: 1px;
    right: 1px;
    background-color: #ffffff;
    font-size: 20px;
    font-style: italic;
    color: #b4b4b4;    
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}


.my-requests-placeholder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4px);
    background: #fff;
    height: calc(100% - 48px - 40px);
    bottom: 1px;
    right: 1px;
    background-color: #ffffff;
    font-size: 20px;
    font-style: italic;
    color: #b4b4b4;    
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
