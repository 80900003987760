.react-table {
    font-family: "Helvetica, Trebuchet MS", Arial, sans-serif;
    border-collapse: collapse;
    overflow: hidden;
    position: relative;
}

.react-table .thead {
    overflow-y: auto;
    overflow-x: hidden;
}

.react-table .tr {
    margin: 0 0 10px;
    padding: 8px 4px;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: fit-content;
    min-width: -webkit-fill-available !important;
    width: -moz-available;
}

.react-table .tr.scrollable {
    padding-right: 4px;
    background-color: #000;
}

.react-table .th {
    padding: 0 8px;
    
    color: #262626;
    font-size: 1.4rem;
    font-weight: bold;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.react-table .td {
    padding: 0 8px;
    color: #262626;
    font-size: 1.4rem;
    line-height: 34px;
    white-space: nowrap;
    overflow: hidden;
    height: 34px;
    text-overflow: ellipsis;
}

.react-table .tr.selected {
    background-color: #ffedeb;
    font-weight: bold;
}

.react-table .th {
    min-height: 34px;
    text-overflow: ellipsis;
}

.react-table .th .div {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0
}

.react-table .tbody .tr:hover{
    background-color: #ffedeb;
}

.react-table .MuiIconButton-root {
    padding: 0;
}

.th div div {
    padding: 0;
}

.react-table .MuiPaper-rounded {
    border-radius: 0;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0
}

.react-table .placeholder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4px);
    height: 100%;
    bottom: 0px;
    right: 0px;
    border-radius: 4px;
    font-size: 2rem;
    font-style: italic;
    color: #b4b4b4;  
}

.react-table .tr:hover .table-row-actions {
    visibility: visible !important;
}

.react-table .table-row-actions {
    display: inline-block;
    float: right;
    visibility: hidden;
}

.react-table .table-row-actions button:not(:first-child) {
    margin-left: 4px;
}